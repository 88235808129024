import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { VesselToolbarComponent } from '../vessel-toolbar/vessel-toolbar.component';
import { Certificate, Vessel, VesselType, VesselsService, Voyage } from '../../vessels.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { Organisation, OrganisationService } from 'src/app/organisation/organisation.service';
import { ActivatedRoute } from '@angular/router';
import { LookupService } from 'src/app/shared/services/lookup.service';
import moment from 'moment';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { DateTimeHelpers } from 'src/app/shared/helpers/date-time.helpers';
import { DocumentService } from 'src/app/documents/documents.service';
import { DataBaseActionService, ActionType } from 'src/app/shared/services/database-action.service';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { Crew, CrewChange, CrewChangeServices } from '../../crew-change/crew-change.service';
import { KeycloakService } from 'src/app/authentication/keycloak.service';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesService } from '../../../../shared/datatables/data-tables.service';

@Component({
  selector: 'app-vessel-data-entry',
  templateUrl: './vessel-data-entry.component.html',
})
export class VesselDataEntryComponent implements OnInit {
  toolbarComponentType = VesselToolbarComponent;
  toolbarComponent: VesselToolbarComponent;
  title = new EventEmitter<string>();
  vesselTypes: Observable<VesselType[]>;
  vessel: Vessel;
  photo: any;
  volumes: any;
  selectedVoyage: Voyage;
  certificates: Certificate[];
  _filteredCertificates = [];
  set filteredCertificates(value) {
    this._filteredCertificates = value;
    this.initialiseCertificatesDataTable();
  }
  get filteredCertificates() {
    return this._filteredCertificates;
  }
  role: boolean;
  q88FileId: string;
  certificateFileId: string;
  formCFileId: string;
  organisations: Organisation[];
  onBoardCrews: Crew[];
  crew: Crew[];
  crewChanges: CrewChange[];
  master: string;

  fineCertificates = [];
  warningCertificates = [];
  dangerCertificates = [];
  expiredCertificates = [];

  calculateLaytime = VesselsService.calculateLaytime;
  calculateBerthTime = VesselsService.calculateBerthTime;
  voyageLengthFromLoadingToDischarge = VesselsService.voyageLengthFromLoadingToDischarge;

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  isDataTablesInitialised = false;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: true })
  crewDtElement: DataTableDirective;
  isCrewDataTablesInitialised = false;
  crewDtOptions = {};
  crewDtTrigger: Subject<any> = new Subject<any>();


  constructor(private _vesselsService: VesselsService,
    private _crewChangeService: CrewChangeServices,
    private _activateRoute: ActivatedRoute,
    private _lookupService: LookupService,
    private _actionService: DataBaseActionService,
    private _keycloakService: KeycloakService,
    private _pgMessagesService: PgMessagesService,
    private _documentsService: DocumentService,
    private _organisationService: OrganisationService) { }

  ngOnInit(): void {
    this.master = '';
    this.vesselTypes = this._vesselsService.getVesselTypes();
    this.dtOptions = DataTablesService.getOptionsWithNoColumnsEn([]);
    this.crewDtOptions =  DataTablesService.getOptionsWithNoColumnsEn([]);

    this._activateRoute.params.subscribe(params => {
      const imo = params['imo'];
      if (imo) {
        forkJoin([
          this._vesselsService.getVessel(imo),
          this._lookupService.get('vesselCertificate'),
          this._organisationService.get(),
          this._vesselsService.getVoyagesByIMO(imo),
        ]).subscribe(result => {
          this.vessel = result[0];
          if (result[0].documents.find(d => d.documentType === 'Q88')) {
            const q = result[0].documents.filter(d => d.documentType === 'Q88')
            .sort((a, b) => b.issueDate - a.issueDate);
            this.q88FileId = q[0].documentId;
          }
          this.certificates = result[0].certificates;
          this.filteredCertificates = this.certificates;
          this.initialiseCertificatesDataTable();
          this.extractCertificateStatus();

          this.organisations = result[2];
          if (this.vessel.spec.photoIds) {
            this._documentsService.download(this.vessel.spec.photoIds[length - 1]).subscribe(b => {
              const reader = new FileReader();
              reader.readAsDataURL(b.body);
              reader.onload = () => {
                this.photo = reader.result;
              };
            });
          }
          if (result[0].documents.find(d => d.documentType === 'Form C')) {
            const q = result[0].documents.filter(d => d.documentType === 'Form C')
            .sort((a, b) => b.issueDate - a.issueDate);
            this.formCFileId = q[0].documentId;
          }
          this.title.emit(`${result[0].name.name}`);
          const sorted = result[3].sort((a, b) => {
            if(b.nors.length > 0 && a .nors.length > 0) {
              return  b.nors.filter(n => n.norType === 'loading').sort((n1, n2) => n1.timestamp - n2.timestamp)[0].timestamp -
                a.nors.filter(n => n.norType === 'loading').sort((n1, n2) => n1.timestamp - n2.timestamp)[0].timestamp;
            } else {
              return b.shipVoyageNo - a.shipVoyageNo;
            }
          });

          this.selectedVoyage = sorted[0];
          this.volumes = this.calculateVolumes();
        });
        if (this._keycloakService.hasRole('vessels-crew-viewer')) {
          this._crewChangeService.getOnBoardCrewByIMO(imo)
          .subscribe(result => {
            this.onBoardCrews = result;
            this.initialiseCrewsDataTable();
            const m = this.onBoardCrews.find(s => this.getLastCrewChange(s.crewChanges).rank === 'Master');
            if (m) {
              this.master = `${m.firstName} ${m.lastName}`;
            }
          });
        }
      } else {
        this.title.emit('New Vessel');
      }
    });
  }


  colorCode(date: number) {
    const oneDayThreshold = moment().add(1, 'days').unix() * 1000;
    const oneMonthThreshold = moment().add(1, 'months').unix() * 1000;
    const threeMonthThreshold = moment().add(3, 'months').unix() * 1000;
    return (date < oneDayThreshold) ? 'text-red' :
      (date > oneDayThreshold && date <= oneMonthThreshold) ? 'text-yellow' :
      (date > oneMonthThreshold && date <= threeMonthThreshold) ? 'text-green' :
      (date >= threeMonthThreshold) ? 'text-blue' : '';
  }

  extractCertificateStatus() {
    const oneDayThreshold = moment().add(1, 'days').unix() * 1000;
    const oneMonthThreshold = moment().add(1, 'months').unix() * 1000;
    const threeMonthThreshold = moment().add(3, 'months').unix() * 1000;
    this.expiredCertificates = this.certificates.filter(i => i.info.expirationDate < oneDayThreshold);
    this.dangerCertificates = this.certificates.filter(i => i.info.expirationDate > oneDayThreshold && i.info.expirationDate <= oneMonthThreshold);
    this.warningCertificates = this.certificates.filter(i => i.info.expirationDate > oneMonthThreshold && i.info.expirationDate <= threeMonthThreshold);
    this.fineCertificates = this.certificates.filter(i => i.info.expirationDate >= threeMonthThreshold);
  }

  findNorLocations(norType: string) {
    const loadingNors = this.selectedVoyage.nors.filter(n => n.norType === norType);
    if (loadingNors && loadingNors.length > 0) {
      return loadingNors.map(n => this.findOrganisation(n.portOrganisationId));
    }
  }

  initialiseCertificatesDataTable() {
    if (!this.isDataTablesInitialised) {
      this.dtTrigger.next(false);
    } else {
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.dtTrigger.next(false);
      });
    }
    this.isDataTablesInitialised = true;
  }

  initialiseCrewsDataTable() {
    if (!this.isCrewDataTablesInitialised) {
      this.crewDtTrigger.next(false);
    } else {
      this.crewDtElement.dtInstance.then((dtInstance) => {
        dtInstance.destroy();
        this.crewDtTrigger.next(false);
      });
    }
    this.isCrewDataTablesInitialised = true;
  }


  dateTime(timestamp: number) {
    return DateTimeHelpers.formatLocalDate(timestamp);
  }

  vesselActive() {
    this.vessel.active = !this.vessel.active;
    this.vessel.actions.push(this._actionService.userAction(ActionType.Update));
    this._vesselsService.updateVessel(this.vessel).subscribe(() => {
      this._pgMessagesService.publishMessage({
        level: MessageLevel.Info,
        topic: 'Vessel',
        message: `The vessel ${this.vessel.IMO} information edited.`
      });
    },
      () => {
        this._pgMessagesService.publishMessage({
          level: MessageLevel.Error,
          topic: 'Vessel',
          message: 'There is a problem to add this vessel.'
        });
      });
  }

  duration(endDate) {
    const now = new Date().getTime();
    return moment(endDate).diff(now, 'days');
  }

  maxlenght(text: string) {
    if (text.length > 20) {
      return text.substring(0, 20) + '...';
    }
    return text;
  }

  downloadDocument(id: string, name: string) {
        this._documentsService.download(id).subscribe(f => {
          saveAs(f.body, `${this.vessel.name.name}_${name}.pdf`);
        });
  }

  downloadShipParticular(id: string) {
    this._documentsService.download(id).subscribe(f => {
      saveAs(f.body, `${this.vessel.name.name}.xlsx`);
    });
}

  findOrganisation(organisationId: string) {
    if (this.organisations) {
      const organisation = this.organisations.find(p => p.name === organisationId);
      if (organisation) {
        return organisation.nameTranslations['en'];
      } else {
        return 'not found';
      }
    }
  }

  calculateVolumes() {
    if (this.selectedVoyage) {
      const sumOfRevise = (this.selectedVoyage.billOfLadings) ?
        _.chain(this.selectedVoyage.billOfLadings.filter(bill => bill.revise === true).flatMap(b => b.products))
          .groupBy(b => b.product)
          .map((v, k) => {
            const grossWeight = v.reduce((x, y) => x + y.grossWeight, 0);
            return {
              product: k,
              grossWeight
            };
          }).value() : null;

      const sumOfReal = (this.selectedVoyage.billOfLadings) ?
        _.chain(this.selectedVoyage.billOfLadings.filter(bill => bill.revise !== true).flatMap(b => b.products))
          .groupBy(b => b.product)
          .map((v, k) => {
            const grossWeight = v.reduce((x, y) => x + y.grossWeight, 0);
            return {
              product: k,
              grossWeight
            };
          }).value() : null;

      const dischWeight = (this.selectedVoyage.discharges) ?
        _.chain(this.selectedVoyage.discharges.flatMap(dch => dch.measurements.filter(f => f.primary === true)))
          .groupBy(d => d.product)
          .map((v, k) => {
            const weight = v.reduce((x, y) => x + y.weight, 0);
            return {
              product: k,
              weight
            };
          }).value() : null;

      const productDiffs = (dischWeight && sumOfReal) ?
        dischWeight.map(d => {
          const load = sumOfReal.find(b => b['product'] === d['product']);
          let productDiff = null;
          if (load) {
            productDiff = d['weight'] - load['grossWeight'];
          }
          return {
            product: d['product'],
            diff: productDiff
          };
        }) : null;

      const totalLoading = sumOfReal.map(p => p.grossWeight).reduce((x, y) => x + y, 0);
      const totalDischarge = dischWeight.map(p => p.weight).reduce((x, y) => x + y, 0);
      const allDifferences = totalDischarge - totalLoading;
      return {
        loadPorts: this.findNorLocations('loading'),
        dischargePorts: this.findNorLocations('discharging'),
        loadingRealWeight: sumOfReal,
        loadingReviseWeight: sumOfRevise,
        dischargeWeight: dischWeight,
        diff: productDiffs,
        allDiff: allDifferences,
        allLoading: totalLoading,
        allDischarging: totalDischarge,
        noLoadingPorts: this._vesselsService.countPorts(this.selectedVoyage, 'loading'),
        noDischargePorts: this._vesselsService.countPorts(this.selectedVoyage, 'discharge'),
        timeBetweenLoadingDis: this.voyageLengthFromLoadingToDischarge(this.selectedVoyage),
        loadingBerthTime: this.calculateBerthTime(this.selectedVoyage, 'loading'),
        dischargeBerthTime: this.calculateBerthTime(this.selectedVoyage, 'discharge'),
        loadingLaytime: this.calculateLaytime(this.selectedVoyage, 'loading'),
        dischargeLaytime: this.calculateLaytime(this.selectedVoyage, 'discharge')
      };
    }
  }

  downloadFile() {
    this._vesselsService.downloadReportTemplate(this.vessel.IMO).subscribe(b => {
      const n = this.vessel.name.name.split(' ').map(s => s.toLowerCase()).join('-');
      saveAs(b.body, `${n}-${this.vessel.IMO}-report-template.xlsx`);
    }, () => {
      this._pgMessagesService.publishMessage({
        message: 'Could not download report template.',
        level: MessageLevel.Error,
        topic: 'Vessel report template'
      });
    });
  }

  hasRole(role: string) {
    return this._keycloakService.hasRole(role);
  }

  getLastCrewChange(crewChanges: CrewChange[]) {
    return this._crewChangeService.getLastCrewChange(crewChanges);
  }

  onboardTimeMonthsDays(crewChanges: CrewChange[]) {
    const crewChange = this._crewChangeService.getLastCrewChange(crewChanges);
    return  this._crewChangeService.onboardTimeMonthsDays(crewChange);
  }

  travelTimeDays(crewChange: CrewChange) {
    return this._crewChangeService.travelTimeDays(crewChange);
  }

  planTime(crewChanges: CrewChange[], selectedPlan: string) {
    const crewChange = this._crewChangeService.getLastCrewChange(crewChanges);
    if(this.organisations) {
      return this._crewChangeService.planTime(crewChange, selectedPlan, this.organisations);
    }
  }
}


